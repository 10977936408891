import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import * as React from 'react';
import { useState } from 'react';
import { DataTableHead, HeadCell } from './DataTableHead';
import { TableToolbar } from './TableToolbar';
import { useTranslation } from 'react-i18next';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

export type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
	return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
interface WithId {
	id: any;
}

interface TableProps<T> {
	rows: Array<T>;
	tableCells: (row: T) => JSX.Element;
	headCells: Array<HeadCell<T>>;
	withToolbar?: boolean;
	withPagination?: boolean;
	totalItems?: number;
	getPage?: (page: number) => void;
	getRowsPerPage?: (rowsPerPage: number) => void;
	fetchMarkAsPaid?: any;
	showMarkAsPaid?: boolean;
	handleDownloadPaymentCSV?: (paymentIds: number[]) => void;
	handleDownloadUserCSV?: (userIds: number[]) => void;
}

export const DataTable = <T extends WithId>({
	rows,
	headCells,
	tableCells,
	withToolbar,
	withPagination,
	totalItems,
	getPage,
	getRowsPerPage,
	fetchMarkAsPaid,
	showMarkAsPaid,
	handleDownloadPaymentCSV,
	handleDownloadUserCSV
}: TableProps<T>) => {
	const [order, setOrder] = useState<Order>('asc');
	const [orderBy, setOrderBy] = useState<keyof T>('id');
	const [selected, setSelected] = useState<number[]>([]);
	const [page, setPage] = useState(0);
	const [dense, setDense] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { t } = useTranslation();

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = rows.map(n => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: number[] = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		if (getPage) {
			getPage(newPage + 1);
		}
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		if (getRowsPerPage) {
			getRowsPerPage(parseInt(event.target.value, 10));
		}
		setPage(0);
	};

	const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const isSelected = (name: number) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2, '&.MuiPaper-elevation1': { boxShadow: 'none' } }}>
				{withToolbar && (
					<TableToolbar
						showMarkAsPaid={showMarkAsPaid}
						numSelected={selected.length}
						fetchMarkAsPaid={fetchMarkAsPaid}
						selected={selected}
						handleDownloadPaymentCSV={handleDownloadPaymentCSV}
						handleDownloadUserCSV={handleDownloadUserCSV}
					/>
				)}
				<TableContainer>
					<Table
						sx={{
							'.MuiCheckbox-root svg': {
								color: 'primary.main'
							}
						}}
						size={dense ? 'small' : 'medium'}>
						<DataTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy.toString()}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
							headCells={headCells}
							withToolbar={withToolbar}
						/>
						<TableBody>
							{rows.sort(getComparator(order, orderBy) as any).map((row, index) => {
								const isItemSelected = isSelected(row.id);
								const labelId = `users-table-checkbox-${index}`;

								return (
									<TableRow
										hover
										onClick={event => handleClick(event, row.id)}
										role="checkbox"
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={labelId}
										selected={isItemSelected}>
										{withToolbar && (
											<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': labelId
													}}
												/>
											</TableCell>
										)}
										<TableCell component="th" id={labelId} scope="row" padding="normal">
											{row.id}
										</TableCell>
										{tableCells(row)}
									</TableRow>
								);
							})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (dense ? 33 : 53) * (emptyRows - (page + 1) * rowsPerPage)
									}}>
									<TableCell colSpan={7}></TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				{withPagination && (
					<Box sx={{ display: 'flex', paddingTop: '70px', justifyContent: 'space-between' }}>
						<FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label={`${t('Dense padding')}`} />
						<TablePagination
							rowsPerPageOptions={[10, 25, 50]}
							labelRowsPerPage={t('rows')}
							showFirstButton={true}
							showLastButton={true}
							component="div"
							count={totalItems ? totalItems : rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Box>
				)}
			</Paper>
		</Box>
	);
};
