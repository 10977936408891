export { useModal } from './useModal';
export { useOnClickOutside } from './useOnClickOutside';
export { useDate } from './useDate';

export const getObjectName = (type: number) => {
	switch (type) {
		case 1:
			return 'Manhole';
		case 2:
			return 'Bench';
		case 3:
			return 'Distribution Cabinet';
		case 4:
			return 'Roadblock';
		case 5:
			return 'Trashcan';
		case 6:
			return 'Drainage';
		case 7:
			return 'Transformer';
		case 8:
			return 'Slippery path';
		case 9:
			return 'Snow plowed';
		case 10:
			return 'Unplowed path';
		case 11:
			return 'Road defect';
		case 12:
			return 'Himalayan balsam';
		case 13:
			return 'Giant hogweed';
		case 14:
			return 'Lupine';
		case 15:
			return 'Japanese rose';
		case 16:
			return 'Rough path';
		case 17:
			return 'Drain cover';
		case 18:
			return 'Curbside drain cover';
		case 19:
			return 'Water valve cover';
		case 20:
			return 'Japanese knotweed';
		case 25:
			return 'Canada Goldenrod';
		case 26:
			return 'False Spiraea';
		case 27:
			return 'Culvert';
		case 28:
			return 'Spanish Slug';
		case 29:
			return 'Lesser periwinkle';
		case 30:
			return 'Buddleia';
		case 31:
			return "Parrot's feather";
		case 32:
			return "Goat's rue";
		case 33:
			return 'Cherry laurel';
		case 34:
			return 'Chameleon plant';
		case 35:
			return 'Cypress spurge';
		case 36:
			return 'Himalayan honeysuckle';
		case 37:
			return 'Italian arum';
		case 38:
			return 'Mexican fleabane';
		case 39:
			return 'Purpletop vervain';
		case 40:
			return 'Bus stop';
		case 41:
			return 'Disabled parking spot';
		case 42:
			return 'Public transport spot';
		case 43:
			return 'Pedestrian crossing';
	}
};
