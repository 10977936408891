import React, { useState, useMemo, useCallback, FormEvent } from 'react';
import { Grid, TextField, Button, Card, Checkbox, Box, Paper } from '@mui/material';
import { API } from '../../../api';
import { ROUTES } from '../../../parameters/routes';
import { useHistory } from 'react-router-dom';
import { useCallbackWithLoading } from '../../../hooks/use-callback-with-loading';
import styles from '../UserManagement.module.scss';
import authStore from '../../../stores/authStore';

import { ReactComponent as DuckFootLogo } from '../../../assets/duck_foot_logo.svg';

const Login = () => {
	const history = useHistory();
	const [data, setData] = useState({ email: '', password: '' });
	const [login, loginPending] = useCallbackWithLoading(API.login);
	const canSubmit = useMemo(() => !!data.email && !!data.password, [data]);
	const [error, setError] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const { firstRoute } = authStore;

	const toggleRememberMe = useCallback(() => setRememberMe(v => !v), []);

	const handleDataChange = useCallback(({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		setError(false);
		setData(d => ({ ...d, [currentTarget.id]: currentTarget.value }));
	}, []);

	const handleSubmit = useCallback(
		async (e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			if (!canSubmit) {
				return;
			}
			setError(false);

			try {
				await login(data.email, data.password, { rememberMe });
				history.push(firstRoute);
				authStore.setfirstRoute(ROUTES.home);
				window.location.reload(); // this will fix bug with material ui not displaying styles at login
			} catch (error) {
				setError(true);
				setData(d => ({ ...d, password: '' }));
			}
		},
		[canSubmit, login, data.email, data.password, rememberMe, history, firstRoute]
	);

	return (
		<Box display={'flex'} justifyContent={'center'} alignItems={'center'} minHeight={'100vh'} style={{ backgroundColor: '#ECECEC' }}>
			<Paper elevation={3} style={{ borderRadius: '10px' }}>
				<form onSubmit={handleSubmit}>
					<Box display={'flex'} flexDirection={'column'} gap="10px" padding={'30px'} width="340px" alignContent={'center'}>
						<DuckFootLogo style={{ height: '100px', paddingBottom: '20px', width: '100%' }} />
						<TextField required id="email" label="Email" onChange={handleDataChange} />
						<TextField required id="password" label="Password" type="password" autoComplete="current-password" onChange={handleDataChange} />
						<Box>
							<Checkbox id="check" name="checkbox" checked={rememberMe} onChange={toggleRememberMe} />
							<span className={styles.remember_me_text}>Remember me</span>
						</Box>
						<Box display={'flex'} flexDirection="column" gap="10px" paddingTop={'20px'}>
							<Button type="submit" variant="contained" disabled={!canSubmit || loginPending}>
								Login
							</Button>
							<Button variant="outlined" disabled={loginPending} onClick={() => history.push(ROUTES.passwordRequest)}>
								Forgot password
							</Button>
						</Box>
						<Card elevation={0}>
							<Grid container direction="column" justifyContent="center" alignItems="center" margin="10px"></Grid>
						</Card>
						{error ? <div className={styles.error}>The provided email and password do not match.</div> : null}
					</Box>
				</form>
			</Paper>
		</Box>
	);
};

export default Login;
