import React, { useState } from 'react';
import { API } from '../../../api';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

export const RejectWithPaymentDialog = (props: { isOpen: boolean; onClose: () => void; uploadId: number; onItemDialogClose: () => void }) => {
	const { isOpen, onClose, uploadId, onItemDialogClose } = props;

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleRejectWithPayment = () => {
		setIsLoading(true);

		API.patchUploads(uploadId, 'rejectWithPayment')
			.then(res => {
				toast.success(`Upload ${uploadId} rejected with payment`, { autoClose: 1000 });
				updateResponse(uploadId, 'rejectedWithPayment');
				setIsLoading(false);
				onClose();
				onItemDialogClose();
			})
			.catch(err => {
				toast.error(`Error rejecting with payment this upload ${uploadId}: ${err.message}`, { autoClose: 1000 });
				console.error('Error rejecting with payment: ', err);
				setIsLoading(false);
				onClose();
			});
	};

	const handleCancel = () => {
		onClose();
	};

	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth>
			<DialogTitle>Reject Upload With Payment</DialogTitle>
			<DialogContent>
				<div>Are you sure you want to reject this upload with payment?</div>
			</DialogContent>
			<DialogActions>
				<LoadingButton color="primary" onClick={handleRejectWithPayment} loading={isLoading}>
					Confirm
				</LoadingButton>
				<LoadingButton color="error" onClick={handleCancel} loading={isLoading}>
					Cancel
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
