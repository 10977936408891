import { action, decorate, observable, toJS } from 'mobx';
import { API } from '../api';
import * as yup from 'yup';
import { t } from 'i18next';
import { MissionType } from '../routes/core/videos/Uploads';

export enum AvailableLanguages {
	en = 'en',
	fi = 'fi',
	sv = 'sv',
	et = 'et',
	pl = 'pl',
	ko = 'ko',
	fr = 'fr'
}

export const LanguageNames: { [key in AvailableLanguages]: string } = {
	[AvailableLanguages.en]: 'English',
	[AvailableLanguages.fi]: 'Finnish',
	[AvailableLanguages.sv]: 'Swedish',
	[AvailableLanguages.et]: 'Estonian',
	[AvailableLanguages.pl]: 'Polish',
	[AvailableLanguages.ko]: 'Korean',
	[AvailableLanguages.fr]: 'French'
};

export interface Project {
	[key: string]: any;
	id: number;
	projectTypeId: number;
	name: { [key in AvailableLanguages]?: string };
	countryId: number;
	dateStart: number | Date;
	lat: string;
	lon: string;
	cards: ProjectCard[];
	clientUsers: number[];
	description: string;
	privateUsers: number[];
	newReferences: any[];
	isPrivate: number;
	references: any[];
	budget: number;
	newSightingLimit: number;
	csvFile: any;
	vehicleId?: number;
	selectedObjects: ChoiceObject[];
	invasiveObjects?: InvasiveObject[];
}

export interface InvasiveObject {
	object: number;
	initial_window: number;
	redo_window: number;
	value_multiplier: number;
	disabled: boolean;
	values: {
		m_square: number;
		value: number;
	}[];
}

export interface ChoiceObject {
	ID: number;
	name: number;
	number: number; // This is the number of photos that is required for photo missions..
	objectTypeId: number;
	value: number;
}

export enum ProjectCardType {
	OBJECTIVE = 1,
	METHOD = 2,
	EQUIPMENT = 3,
	CONDITIONS = 4,
	SAFETY = 5,
	LEADERBOARD = 6
}

interface ProjectCardTypeNames {
	[ProjectCardType.OBJECTIVE]: string;
	[ProjectCardType.METHOD]: string;
	[ProjectCardType.EQUIPMENT]: string;
	[ProjectCardType.CONDITIONS]: string;
	[ProjectCardType.SAFETY]: string;
	[ProjectCardType.LEADERBOARD]: string;
}

export const projectCardTypeNames: ProjectCardTypeNames = {
	[ProjectCardType.OBJECTIVE]: 'objective',
	[ProjectCardType.METHOD]: 'method',
	[ProjectCardType.EQUIPMENT]: 'equipment',
	[ProjectCardType.CONDITIONS]: 'conditions',
	[ProjectCardType.SAFETY]: 'safety',
	[ProjectCardType.LEADERBOARD]: 'leaderboard'
};

export type ProjectCard = {
	id: number;
	type: ProjectCardType;
	content: { [key in AvailableLanguages]?: string };
	missionProgress?: number;
	totalPlayerEarnings?: number;
	remainingRewards?: number;
};

const initialMissionData: Project = {
	id: 0,
	projectTypeId: 0,
	description: '',
	name: {},
	countryId: -1,
	isPrivate: 0,
	dateStart: new Date(),
	lat: '',
	lon: '',
	clientUsers: [],
	budget: 0,
	newSightingLimit: 0,
	privateUsers: [],
	cards: [
		{
			id: 0,
			type: ProjectCardType.OBJECTIVE,
			content: { en: '' }
		},
		{
			id: 0,
			type: ProjectCardType.METHOD,
			content: { en: '' }
		},
		{
			id: 0,
			type: ProjectCardType.EQUIPMENT,
			content: { en: '' }
		},
		{
			id: 0,
			type: ProjectCardType.CONDITIONS,
			content: { en: '' }
		},
		{
			id: 0,
			type: ProjectCardType.SAFETY,
			content: { en: '' }
		}
	],
	references: [],
	newReferences: [],
	// Video project specifics
	csvFile: undefined,
	// Photo project specifics
	selectedObjects: []
};

const cardContentSchema = yup.lazy(obj => {
	// Create a dynamic schema based on the keys in the object
	let schema = yup.object().shape({});
	Object.keys(obj).forEach(key => {
		if (Object.values(AvailableLanguages).includes(key)) {
			// Add a required string validation for each key found in the object
			schema = schema.shape({
				[key]: yup.string().required()
			});
		}
	});
	return schema;
});

const nameSchema = yup.lazy(obj => {
	// Create a dynamic schema based on the keys in the name
	let schema = yup.object().shape({});
	Object.keys(obj).forEach(key => {
		if (Object.values(AvailableLanguages).includes(key)) {
			schema = schema.shape({
				// make the string required if the language is present
				[key]: yup.string().required()
			});
		}
	});
	return schema;
});

const projectSchema = yup.object().shape({
	id: yup.number().min(0),
	projectTypeId: yup.number().min(0).required(),
	budget: yup.number().required().min(1),
	newSightingLimit: yup.number().when('projectTypeId', {
		is: (value: number) => value === MissionType.invasiveSpecies,
		then: yup.number().required().min(1),
		otherwise: yup.number().notRequired()
	}),
	name: nameSchema,
	countryId: yup
		.number()
		.min(0)
		.required()
		.typeError(() => t('projectEditor.projectLocation.countryError')),
	lat: yup
		.number()
		.required(() => t('projectEditor.projectLocation.latitudeError'))
		.typeError(() => t('projectEditor.projectLocation.latitudeError') as string),
	lon: yup
		.number()
		.required(() => t('projectEditor.projectLocation.longitudeError'))
		.typeError(() => t('projectEditor.projectLocation.longitudeError') as string),
	cards: yup.array().of(
		yup
			.object()
			.shape({
				id: yup.number().min(0),
				type: yup.number().min(1).required(),
				content: cardContentSchema
			})
			.optional()
	)
});

export class ProjectEditorStore {
	project: Project;
	errors: any = {};
	selectedLanguages: AvailableLanguages[] = [AvailableLanguages.en];
	selectedLanguage: AvailableLanguages = AvailableLanguages.en;

	constructor() {
		this.project = initialMissionData;
	}

	validateProject = async () => {
		try {
			await projectSchema.validate(this.project, { abortEarly: false });
		} catch (error: any) {
			const errorObject: any = {};
			console.error(error.inner);

			error.inner.forEach((error: any) => {
				errorObject[error.path] = error.message;
			});

			return errorObject;
		}
	};

	createNewLocalisation = (language: AvailableLanguages) => {
		// Keeping track internally of which languages are selected
		this.selectedLanguages.push(language);

		// If there is no project cards, create fresh ones and initialize them with empty strings for the language chosen
		if (this.project.cards.length == 0) {
			const emptyLanguageCards = [
				ProjectCardType.OBJECTIVE,
				ProjectCardType.METHOD,
				ProjectCardType.EQUIPMENT,
				ProjectCardType.CONDITIONS,
				ProjectCardType.SAFETY
			].map((type: ProjectCardType) => {
				return {
					id: 0,
					type,
					content: {
						[language]: ''
					}
				};
			});

			this.project.cards = [...emptyLanguageCards];
		} else {
			// If there are already cards, just add the new language to the content object
			this.project.cards.forEach((card: any) => {
				card.content[language] = '';
			});

			this.project.name[language] = '';
		}
	};

	deleteLocalisation = (language: AvailableLanguages) => {
		// We don't delete Englis localisations. It should always be present.
		if (language == AvailableLanguages.en) return;

		// Remove the language from the selected languages.
		this.selectedLanguages = this.selectedLanguages.filter((selectedLanguage: AvailableLanguages) => {
			return selectedLanguage !== language;
		});

		// Remove the content from each card
		this.project.cards.forEach((card: any) => {
			delete card.content[language];
		});

		// Remove the name localisation
		delete this.project.name[language];

		// And set the language to something so that that a tab is selected in the ui
		this.setSelectedLanguage(this.selectedLanguages[0]);
	};

	setProjectTypeId = (projectTypeId: number) => {
		this.project.projectTypeId = projectTypeId;
	};

	setSelectedLanguage = (language: AvailableLanguages) => {
		this.selectedLanguage = language;
	};

	clearProject = () => {
		this.project = initialMissionData;
	};

	async validateAll() {
		console.group('VALIDATING PROJECT');
		const [projectValidationResult] = await Promise.all([this.validateProject()]);

		console.log('errors', toJS(this.errors));
		console.groupEnd();
		this.errors = { ...projectValidationResult };
	}

	async saveProject(): Promise<number> {
		// This function will do the validation for the project and then attempt to save it to the server.
		await this.validateAll();

		if (Object.keys(this.errors).length == 0) {
			switch (this.project.projectTypeId) {
				case MissionType.photo:
					delete this.project.vehicleId;
			}

			const saveProjectResponse = await API.saveProject(this.project);
			const projectId = saveProjectResponse.data.projectId;

			if (!projectId) {
				return Promise.reject('Error saving project');
			}

			return Promise.resolve(projectId);
		} else {
			return Promise.reject('Error validating project');
		}
	}

	async setProject(project: Project) {
		this.project = project;

		this.selectedLanguages = [];
		// Load which languages are selected
		Object.keys(this.project.name).forEach((key: string) => {
			this.selectedLanguages.push(key as AvailableLanguages);
		});

		// I'm not sure why, but the name is already parsed.
		//project.name = JSON.parse(project.name as string);

		// but the card contents are not, so parse them here.
		this.project.cards = this.project.cards.map((card: any) => {
			return { ...card, content: JSON.parse(card.content) };
		});
	}
}

decorate(ProjectEditorStore, {
	project: observable,
	selectedLanguages: observable,
	errors: observable,
	selectedLanguage: observable,

	createNewLocalisation: action.bound,
	deleteLocalisation: action.bound,
	setProjectTypeId: action.bound,
	saveProject: action.bound,
	setProject: action.bound,
	setSelectedLanguage: action.bound,
	clearProject: action.bound
});

export default new ProjectEditorStore();
