import React, { useEffect, useState } from 'react';
import { ProjectEditorSection } from './ProjectEditorComponents';
import { API } from '../../../api';
import { toast } from 'react-toastify';
import { use } from 'i18next';
import projectEditorStore, { ChoiceObject } from '../../../stores/projectEditorStore';

import { ReactComponent as DistributionCabinet } from '../../../assets/distribution-cabinet.svg';
import { ReactComponent as Drainage } from '../../../assets/drainage.svg';
import { ReactComponent as Bench } from '../../../assets/bench-object.svg';
import { ReactComponent as ManholeCover } from '../../../assets/manhole-cover.svg';
import { ReactComponent as Roadblock } from '../../../assets/roadblock.svg';
import { ReactComponent as Transformer } from '../../../assets/transformer.svg';
import { ReactComponent as Trashcan } from '../../../assets/trashcan.svg';
import { ReactComponent as SlipperyPath } from '../../../assets/slippery-path.svg';
import { ReactComponent as SnowPlowed } from '../../../assets/snow-plowed.svg';
import { ReactComponent as UnplowedPath } from '../../../assets/unplowed-path.svg';
import { ReactComponent as RoadDefect } from '../../../assets/road-defect.svg';
import { ReactComponent as HimalayanBalsam } from '../../../assets/himalayan-balsam.svg';
import { ReactComponent as GiantHogweed } from '../../../assets/giant-hogweed.svg';
import { ReactComponent as Lupine } from '../../../assets/lupine.svg';
import { ReactComponent as JapaneseRose } from '../../../assets/japanese-rose.svg';
import { ReactComponent as RoughPath } from '../../../assets/rough-path.svg';
import { ReactComponent as DrainCover } from '../../../assets/drain-cover.svg';
import { ReactComponent as CurbsideDrainCover } from '../../../assets/curbside-drain-cover.svg';
import { ReactComponent as WaterValveCover } from '../../../assets/water-valve-cover.svg';
import { ReactComponent as JapaneseKnotweed } from '../../../assets/japanese-knotweed.svg';
import { ReactComponent as BrokenSoundBeacon } from '../../../assets/broken-sound-beacon.svg';
import { ReactComponent as WorkingSoundBeacon } from '../../../assets/working-sound-beacon.svg';
import { ReactComponent as DroppedCurb } from '../../../assets/dropped-curb.svg';
import { ReactComponent as PedestrianPath } from '../../../assets/pedestrian-path.svg';
import { ReactComponent as CanadaGoldenrod } from '../../../assets/canada_goldenrod.svg';
import { ReactComponent as FalseSpiraea } from '../../../assets/false_spiraea.svg';
import { ReactComponent as Culvert } from '../../../assets/culvert.svg';
import { ReactComponent as SpanishSlug } from '../../../assets/spanish_slug.svg';
import { ReactComponent as LesserPeriwinkle } from '../../../assets/lesser_periwinkle.svg';
import { ReactComponent as Buddleia } from '../../../assets/buddleia.svg';
import { ReactComponent as ParrotsFeather } from '../../../assets/parrots_feather.svg';
import { ReactComponent as GoatsRue } from '../../../assets/goats_rue.svg';
import { ReactComponent as CherryLaurel } from '../../../assets/cherry_laurel.svg';
import { ReactComponent as BusStop } from '../../../assets/bus_stop.svg';
import { ReactComponent as DisabledParkingSpot } from '../../../assets/disabled_parking_spot.svg';
import { ReactComponent as PublicTransportSpot } from '../../../assets/public_transport_spot.svg';
import { ReactComponent as PedestrianCrossing } from '../../../assets/pedestrian_crossing.svg';
import { ReactComponent as Frog } from '../../../assets/frog.svg';

import { Box, Checkbox, TextField } from '@mui/material';
import { observer } from 'mobx-react';

function SvgCheckbox(props: { object: ChoiceObject; checked: boolean; disabled: boolean; checkObject: () => void }) {
	const { object, checkObject, checked, disabled } = props;
	const backgroundColor = checked ? 'rgb(19,141,144,0.5)' : checked ? 'rgb(245,218,72,0.5)' : 'rgb(0,0,0,0.1)';

	const containerStyle = { width: '58px', height: '58px', background: backgroundColor };

	switch (object.ID) {
		case 1:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<ManholeCover />}
					icon={<ManholeCover style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 2:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Bench />}
					icon={<Bench style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 3:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<DistributionCabinet />}
					icon={<DistributionCabinet style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 4:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Roadblock />}
					icon={<Roadblock style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 5:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Trashcan />}
					icon={<Trashcan style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 6:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Drainage style={{ filter: 'none' }} />}
					icon={<Drainage style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 7:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Transformer />}
					icon={<Transformer style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 8:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<SlipperyPath style={{ filter: 'none' }} />}
					icon={<SlipperyPath style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 9:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<SnowPlowed />}
					icon={<SnowPlowed style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 10:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<UnplowedPath />}
					icon={<UnplowedPath style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 11:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<RoadDefect />}
					icon={<RoadDefect style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 12:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<HimalayanBalsam />}
					icon={<HimalayanBalsam style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 13:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<GiantHogweed />}
					icon={<GiantHogweed style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 14:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Lupine />}
					icon={<Lupine style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 15:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<JapaneseRose />}
					icon={<JapaneseRose style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 16:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<RoughPath />}
					icon={<RoughPath style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 17:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<DrainCover />}
					icon={<DrainCover style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 18:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<CurbsideDrainCover />}
					icon={<CurbsideDrainCover style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 19:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<WaterValveCover />}
					icon={<WaterValveCover style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 20:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<JapaneseKnotweed />}
					icon={<JapaneseKnotweed style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 20:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<JapaneseKnotweed />}
					icon={<JapaneseKnotweed style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);

		case 21:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<BrokenSoundBeacon />}
					icon={<BrokenSoundBeacon style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);

		case 22:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<WorkingSoundBeacon />}
					icon={<WorkingSoundBeacon style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);

		case 23:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<PedestrianPath />}
					icon={<PedestrianPath style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 24:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<DroppedCurb />}
					icon={<DroppedCurb style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);

		case 25:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<CanadaGoldenrod />}
					icon={<CanadaGoldenrod style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 26:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<FalseSpiraea />}
					icon={<FalseSpiraea style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 27:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Culvert />}
					icon={<Culvert style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 28:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<SpanishSlug />}
					icon={<SpanishSlug style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 29:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<LesserPeriwinkle />}
					icon={<LesserPeriwinkle style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);

		case 30:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Buddleia />}
					icon={<Buddleia style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);

		case 31:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<ParrotsFeather />}
					icon={<ParrotsFeather style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);

		case 40:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<BusStop />}
					icon={<BusStop style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 41:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<DisabledParkingSpot />}
					icon={<DisabledParkingSpot style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 42:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<PublicTransportSpot />}
					icon={<PublicTransportSpot style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 43:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<PedestrianCrossing />}
					icon={<PedestrianCrossing style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);

		case 44:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Frog />}
					icon={<Frog style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject()}
					style={containerStyle}
					disabled={disabled}
				/>
			);

		default:
			return <></>;
	}
}
export const ProjectPhotoEditor = observer(() => {
	const { project } = projectEditorStore;
	const [objects, setObjects] = useState<ChoiceObject[]>([]);

	useEffect(() => {
		API.getObjects()
			.then(res => {
				const { objects } = res.data;
				// @ts-ignore
				setObjects(objects);
			})
			.catch(err => {
				console.error(err);
				toast.error('Error loading objects');
			})
			.finally(() => {});
	}, []);

	return (
		<ProjectEditorSection title="Photos" tooltip="Add photos to your project">
			<Box sx={{ gap: '10px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
				<Box sx={{ gap: '10px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
					{objects.map(object => {
						const checked = project.selectedObjects.some(o => o.ID === object.ID);

						const onClick = () => {
							const index = project.selectedObjects.findIndex(o => o.ID === object.ID);

							if (index === -1) {
								project.selectedObjects.push(object);
							} else {
								project.selectedObjects.splice(index, 1);
							}
						};
						return <SvgCheckbox object={object} checked={checked} disabled={project.id > 0} checkObject={() => onClick()} />;
					})}
				</Box>

				<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', paddingTop: '20px' }}>
					{project.selectedObjects.map(object => {
						const onChange = (e: any) => {
							const index = project.selectedObjects.findIndex(o => o.ID === object.ID);
							project.selectedObjects[index].value = e.target.value;
						};

						return <TextField type="number" onChange={onChange} disabled={project.id > 0} label={object.name} value={object.value} />;
					})}
				</Box>
			</Box>
		</ProjectEditorSection>
	);
});
